import React from "react"
import ReactHtmlParser from 'react-html-parser'

import { Link, graphql } from "gatsby"
import clip from "text-clipper"

import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../parts/layout"
import Seo from "../parts/seo"


const DomainsPage = ({ data }) => {
  return (
    <Layout>
      <Seo title="Domains" />
      <section>
        <div className="row">
          <div className="page-introduction">{ ReactHtmlParser(data.strapiDomainsPage.introduction) }</div>
          <div className="domains">
            { data.allStrapiDomain.nodes.map(domain => {
              const excerpt = domain.excerpt ? domain.excerpt : clip(domain.description, 150, { html: true })

              return (
                <Link to={`/domains/${domain.slug}`} key={domain.id} className="domain box">
                  { domain.image && <div className="image"><GatsbyImage
                    image={domain.image.localFile.childImageSharp.gatsbyImageData}
                    alt={domain.image.alternativeText}
                    objectFit="contain" /></div> }
                  <div className="text">
                    <h2>{ domain.title }</h2>
                    { ReactHtmlParser(excerpt) }
                    { domain.order }
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default DomainsPage

export const pageQuery = graphql`query DomainQuery {
  strapiDomainsPage {
    introduction
  }
  allStrapiDomain(sort: {fields: order}) {
    nodes {
      id
      slug
      title
      excerpt
      description
      image {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 400
              quality: 80
              placeholder: NONE
              layout: CONSTRAINED
            )
          }
        }
      }
    }
  }
}
`